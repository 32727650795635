@use '~/src/sass/abstracts/variables/colors';

.separator {
	padding: 6rem;
}

.separator__square {
	width: 1rem;
	height: 1rem;
	background-color: colors.$orange;
	margin: 0 auto;
}
